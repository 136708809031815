/**  */

// eslint-disable-next-line
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Global, css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import MenuPrimary from './MenuPrimary';
import MenuSecondary from './MenuSecondary';

import { bp } from '../../../config/breakpoints';

// Assets
import navBg from '../../../assets/icons/nav-bg.svg';

// Vars
import { themeVars } from '../../../config/styles';

// Components
import SocialList from '../../Common/Social/SocialList';

const Menu = ({ isMenuOpen, handleMenuClose }) => {
  const cssNavWrapper = css`
    @media (max-width: ${bp.mx_md}) {
      width: 100%;
    }
  `;

  const cssCloseButtonWrapper = css`
    display: flex;
    margin-left: auto;
    vertical-align: middle;
    line-height: 0;
    justify-content: flex-end;
    position: absolute;
    top: 6.5rem;
    right: 10rem;
    z-index: 15;
    color: ${themeVars.root.primary};
    overflow: hidden;

    @media (max-width: ${bp.mx_md}) {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    &:before {
      content: 'Close';
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      letter-spacing: 1px;
      margin-right: 10px;
    }
  `;

  const cssCloseButton = css`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:focus {
      outline: none;
    }
  `;

  const cssMenu = css`
    width: 50%;
  `;

  const cssMenuContent = css`
    position: relative;
    height: 100vh;
    background: url(${navBg}) center center no-repeat;
    background-size: cover;

    @media (min-width: ${bp.mn_xs}) {
      padding: 15% 75px 10%;
      background-attachment: scroll;
    }

    @media (min-width: ${bp.mn_lg}) {
      background-attachment: fixed;
      padding: 15% 50px 10%;
    }
  `;

  const cssMenuWrapper = css`
    display: flex;
    justify-content: space-between;
  `;

  const cssSocialWrapper = css`
    margin: 0 0 25px 0;

    @media (min-width: ${bp.mn_lg}) {
      margin: 50px 0 25px 0;
      position: absolute;
      bottom: 5%;
    }
  `;

  const cssContent = css`
    width: 100%;

    @media (min-width: ${bp.mn_md}) {
      min-width: 500px;
      width: 25vw;
    }
  `;

  return (
    <Drawer
      anchor="right"
      onClose={handleMenuClose}
      open={isMenuOpen}
      variant="temporary"
      css={cssMenu}
    >
      <Global
        styles={css`
          @media (max-width: ${bp.mx_md}) {
            html {
              overflow-y: hidden;
            }
          }
        `}
      />

      <div css={cssContent}>
        <div css={cssCloseButtonWrapper}>
          <button aria-label="Open menu" css={cssCloseButton} onClick={handleMenuClose}>
            <CloseIcon color="primary" />
          </button>
        </div>

        <div css={cssNavWrapper}>
          <div css={cssMenuContent}>
            <div css={cssMenuWrapper}>
              <MenuPrimary />
              <MenuSecondary />
            </div>
            <div css={cssSocialWrapper}>
              <SocialList color="#C2C7C8" hoverColor={themeVars.root.primary} />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

Menu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
};

export default Menu;
