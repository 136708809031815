/**  */

// eslint-disable-next-line
import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import shortid from 'shortid';
import { css, jsx } from '@emotion/core';

import { themeVars } from '../../../config/styles';

const MenuSecondary = () => {
  const cssNav = css`
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 1.6rem;
        margin-bottom: 20px;

        a {
          text-decoration: none;
          color: ${themeVars.root.primary};

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressWpApiMenusMenusItems(name: { eq: "Secondary" }) {
            items {
              title
              object_slug
              classes
              url
              target
            }
          }
        }
      `}
      render={(data) => {
        return (
          <nav css={cssNav}>
            <ul>
              {data.wordpressWpApiMenusMenusItems.items.map((item) => (
                <li key={shortid.generate()}>
                  <Link to={`/${item.object_slug}/`}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        );
      }}
    />
  );
};

export default MenuSecondary;
