/**  */

// eslint-disable-next-line
import React, { useLayoutEffect, useState } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import shortid from 'shortid';
import { css, jsx } from '@emotion/core';
import posed, { PoseGroup } from 'react-pose';
import { globalHistory } from '@reach/router';

// Vars
import { themeVars } from '../../../config/styles';

const staggerDuration = 75;

const Item = posed.li({
  enter: {
    opacity: 1,
    delay: ({ i }) => i * staggerDuration,
  },
  exit: { opacity: 0 },
});

const MenuPrimary = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Add delay before animating
  useLayoutEffect(() => {
    setInterval(() => {
      setIsOpen(true);
    }, 175);
  }, []);

  const cssNav = css`
    min-height: 80vh;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  `;

  const cssNavItem = css`
    margin-bottom: 25px;

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: ${themeVars.root.primary};
      font-weight: 600;

      &.active {
        color: ${themeVars.root.secondary};
      }

      &:hover {
        text-decoration: underline;
      }
    }
  `;

  return (
    <StaticQuery
      query={graphql`
        query {
          sitePage {
            path
          }

          wordpressWpApiMenusMenusItems(name: { eq: "Primary" }) {
            items {
              title
              object_slug
              classes
              url
              target
            }
          }
        }
      `}
      render={(data) => {
        const currentUrl = globalHistory.location.pathname.split('/').join('');

        return (
          <nav css={cssNav}>
            <ul>
              <PoseGroup animateOnMount>
                {isOpen &&
                  data.wordpressWpApiMenusMenusItems.items.map((item, i) => (
                    <Item css={cssNavItem} key={shortid.generate()} i={i}>
                      {item.object_slug === 'home' ? (
                        <Link to="/" className={currentUrl === item.object_slug ? 'active' : ''}>
                          {item.title}
                        </Link>
                      ) : (
                        <Link
                          to={`/${item.object_slug}/`}
                          className={
                            item.object_slug.split('/').join('') === currentUrl ? 'active' : ''
                          }
                        >
                          {item.title}
                        </Link>
                      )}
                    </Item>
                  ))}
              </PoseGroup>
            </ul>
          </nav>
        );
      }}
    />
  );
};

export default MenuPrimary;
