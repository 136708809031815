/**  */

// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { Link } from 'gatsby';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';

import { bp } from '../../config/breakpoints';

import Logo from '../../assets/images/logo/apex_logo_white.svg';
import Logo_Standard from '../../assets/images/logo/apex_logo.svg';

const Nav = ({ handleMenuOpen }) => {
  const cssWrapper = css`
    padding: 15px 5px;
    @media (min-width: ${bp.mn_lg}) {
      padding: 50px;
    }
  `;

  const cssLogo = css`
    width: 200px;
  `;

  const cssNavButtonWrapper = css`
    display: flex;
    justify-content: flex-end;
    line-height: 0;
    margin-left: auto;
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 50px;
    z-index: 150;
    cursor: pointer;

    @media (max-width: ${bp.mx_md}) {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 90;
    }

    button {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      padding: 0;
      display: flex;
      align-items: center;

      & > span {
        margin-right: 15px;
      }
    }
  `;

  const [homepage, setHomepage] = useState(false);

  useEffect(() => {
      if ( window.location.pathname === '/' ){
        //code for index page
        setHomepage(true);
    }
  });

  return (
    <header css={cssWrapper}>
      <Visible xs sm md>
        <div css={cssNavButtonWrapper}>
          <button type="button" aria-label="Navigation menu" onClick={handleMenuOpen}>
            <span>Menu</span>
            <MenuIcon fontSize="large" />
          </button>
        </div>
      </Visible>
      <Container>
        <Row>
          <Col xl={6} md={6} xs={12}>
            <Link to="/" aria-label="Home">
              <img css={cssLogo} src={homepage ? Logo_Standard : Logo} alt="" />
            </Link>
          </Col>

          <Visible lg xl>
            <Col xl={6} md={6} sm={12}>
              <div css={cssNavButtonWrapper}>
                <button type="button" aria-label="Navigation menu" onClick={handleMenuOpen}>
                  <span>Menu</span>
                  <MenuIcon fontSize="large" />
                </button>
              </div>
            </Col>
          </Visible>
        </Row>
      </Container>
    </header>
  );
};

Nav.propTypes = {
  handleMenuOpen: PropTypes.func.isRequired,
};

export default Nav;
